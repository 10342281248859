<template>
  <div class="chips" v-click-outside="hide">
    <ul class="chips-container">
      <li
        :class="
          validateEmail(chip)
            ? 'chips-container__chip'
            : 'chips-container__chip chips-container__invalid-email'
        "
        v-for="(chip, index) in value"
        :key="index"
      >
        <span class="chips-container__text">{{ chip }}</span>
        <span class="chips-container__button" @click="deleteChip(index)"
          ><i class="pi pi-times-circle"></i
        ></span>
      </li>
      <li class="chips-container__input-container">
        <input
          :key="mountInput"
          class="chips-container__input"
          @keyup.enter="addChip(currentText)"
          type="text"
          :placeholder="placeholder"
          @keydown="hoverSuggestion($event.key.toLowerCase()), getTypedText($event)"
          @input="onInput($event)"
          @blur="addChip(currentText)"
          ref="input"
        />
      </li>
    </ul>
    <div v-if="filteredSuggestions.length" class="suggestions-container" ref="suggestionsList">
      <p
        class="suggestions-container__item"
        :class="index === hoveredSuggestionsIndex ? 'hover-item-background' : ''"
        v-for="(suggestion, index) in filteredSuggestions"
        :key="index"
        @mousedown="addChip(suggestion)"
      >
        {{ suggestion }}
      </p>
    </div>
  </div>
</template>
<script>
import EmailValidation from "../utils/EmailValidation";

export default {
  name: "ChipsInput",
  directives: {
    "click-outside": {
      bind: (el, binding, vnode) => {
        el.clickOutsideEvent = (event) => {
          if (!(el === event.target || el.contains(event.target))) {
            vnode.context[binding.expression](event);
          }
        };
        document.body.addEventListener("click", el.clickOutsideEvent);
      },
      unbind: (el) => {
        document.body.removeEventListener("click", el.clickOutsideEvent);
      },
    },
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    suggestions: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: "",
    },
    validateEmails: Boolean,
    default: () => false,
  },
  data() {
    return {
      currentText: "",
      filteredSuggestions: [],
      mountInput: 0,
      hoveredSuggestionsIndex: -1,
    };
  },
  methods: {
    onInput(event) {
      const inputValue = event.target.value;
      const addChipKeyCodes = [32, 44];
      const keyCode =
        event.which === 8
          ? event.which
          : event.target.value.charAt(event.target.selectionStart - 1).charCodeAt();
      if (addChipKeyCodes.includes(keyCode) && inputValue) {
        this.addChip(inputValue);
        return;
      }
      this.filterSuggestions(event.target.value);
    },
    validateEmail(email) {
      return EmailValidation(email);
    },
    getTypedText(event) {
      const inputValue = event.target.value;
      if (this.suggestions.length) {
        this.filterSuggestions(inputValue);
      }
      const keyCode =
        event.which === 8
          ? event.which
          : event.target.value.charAt(event.target.selectionStart - 1).charCodeAt();
      if (keyCode === 8 && inputValue.length === 0) {
        this.deleteChip(this.value.length - 1);
      }
    },
    addChip(text) {
      if (!text) {
        return;
      }
      let textCopy = text.trim();
      const value = [...this.value];
      if (textCopy[textCopy.length - 1] === ",") textCopy = textCopy.slice(0, -1);
      value.push(textCopy);
      this.$emit("input", value);
      this.$refs.input.value = "";
      this.currentText = "";
      this.mountInput += 1;
      this.filteredSuggestions = [];
      setTimeout(() => {
        this.$refs.input.focus();
      }, 0);
      this.hoveredSuggestionsIndex = -1;
    },
    deleteChip(index) {
      const value = [...this.value];
      value.splice(index, 1);
      this.$emit("input", value);
    },
    filterSuggestions(value) {
      /* eslint-disable implicit-arrow-linebreak */
      this.currentText = value;
      this.filteredSuggestions = this.suggestions.filter((suggestion) =>
        suggestion.toLowerCase().includes(value));
      if (!value) {
        this.filteredSuggestions = [];
      }
    },
    hoverSuggestion(event) {
      const arrowsKeys = ["arrowdown", "arrowup", "enter"];
      if (this.filteredSuggestions.length === 0 || !arrowsKeys.includes(event)) {
        return;
      }
      if (event === "enter" && this.hoveredSuggestionsIndex > -1) {
        this.addChip(this.filteredSuggestions[this.hoveredSuggestionsIndex]);
        return;
      }
      if (
        event === "arrowdown" &&
        this.hoveredSuggestionsIndex < this.filteredSuggestions.length - 1
      ) {
        this.hoveredSuggestionsIndex += 1;
      }
      if (event === "arrowup" && this.hoveredSuggestionsIndex > -1) {
        this.hoveredSuggestionsIndex -= 1;
      }
      this.$nextTick(() => {
        const selectedElement = this.$refs.suggestionsList.children[this.hoveredSuggestionsIndex];
        if (selectedElement) {
          selectedElement.scrollIntoView({
            block: "nearest",
            inline: "start",
          });
        }
      });
    },
    hide() {
      this.filteredSuggestions = [];
    },
  },
};
</script>
<style lang="scss" scoped>
.chips {
  position: relative;
  width: 100%;
  padding: 5px 0px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-end;
  overflow-wrap: anywhere;
}
.chips-container {
  // position: absolute;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-end;
  width: 100%;
  overflow-wrap: anywhere;
  &__input-container {
    flex: 1 1 auto;
    display: inline-flex;
    padding: 0.25rem 0;
  }
  &__input {
    width: 100%;
    border: none;
    border-radius: 10px;
    &:focus {
      outline: 0;
    }
    &::placeholder {
      color: #75757d;
      opacity: 1;
    }

    &::-ms-input-placeholder {
      color: #75757d;
    }
  }
  &__chip {
    display: inline-flex;
    align-items: center;
    flex: 0 0 auto;
    margin-top: 4px;
    margin-right: 8px;
    padding: 0.25rem 0.5rem;
    background-color: $color-gray-light;
    color: $color-primary-company;
    border-radius: 16px;
    list-style-type: none;
    -webkit-box-shadow: 4px 7.5px 5px -7px rgba(150, 150, 150, 0.91);
    -moz-box-shadow: 4px 7.5px 5px -7px rgba(150, 150, 150, 0.91);
    box-shadow: 4px 7.5px 5px -7px rgba(150, 150, 150, 0.91);
  }
  &__invalid-email {
    background-color: $missing-fields;
  }
  &__button {
    display: inline-block;
    margin-left: 0.5rem;
    line-height: 1;
    cursor: pointer;
  }
}
.suggestions-container {
  overflow-x: hidden;
  overflow-y: scroll;
  scrollbar-width: thin;
  position: absolute;
  top: 95%;
  overflow-x: hidden;
  width: 70%;
  max-height: 200px;
  z-index: 1;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 2px 6px 2px rgba(60, 64, 67, 0.15);
  &::-webkit-scrollbar-track {
    background-color: $color-gray;
  }

  &::-webkit-scrollbar {
    width: 5px;
    background-color: #dee2e6;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 20px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: rgba(0, 0, 0, 0.1);
  }
  &__item {
    margin: 0px;
    padding: 6px 0px 3px 11px;
    margin-bottom: 3px;
    cursor: pointer;
    &:hover {
      background-color: #e9e9e9;
    }
  }
}

.hover-item-background {
  background-color: #e9ecef;
}

::placeholder {
  color: $color-gray-dark;
}
</style>
