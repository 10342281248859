<template>
  <div class="share-document">
    <b-overlay :show="loadingShare" rounded="sm">
      <div class="input-container mt-1 mb-1">
        <label class="share-document__label"> Emails </label>
        <div
          class="share-document__emails"
          :class="emptyFields['emails'] === true ? 'missing-field' : ''"
        >
          <Chips
            v-model.trim="body.emails"
            class="input-information-email"
            :placeholder="$t('claimOrDispute.email')"
            :validateEmails="true"
            @input="analyzeValidEmails"
          />
        </div>
        <span class="share-document__missing-email" v-if="validEmails">
          {{ $t("myLoads.shareDocument.missingEmail") }}
        </span>
      </div>
      <div class="input-container mt-1 mb-1">
        <label class="share-document__label" for="email-message"> Message </label>
        <b-form-textarea
          :class="emptyFields['emailMessage'] === true ? 'missing-field' : ''"
          class="share-document__message"
          v-model="body.emailMessage"
          :placeholder="$t('myLoads.shareDocument.messagePlaceholder')"
          rows="3"
          no-resize
          id="email-message"
        ></b-form-textarea>
        <!-- <span class="share-document__missing-text" v-if="emptyFields['emailMessage']">
          {{ $t("myLoads.shareDocument.missingMessage") }}
        </span> -->
      </div>
      <div class="input-container mt-1 mb-1">
        <label class="share-document__label"> Document </label>
        <div class="share-document__document">
          <i class="pi pi-file-pdf share-document__document-icon"></i>
          <span>{{ body.documentName }}</span>
        </div>
      </div>
      <div class="share-document__button-container">
        <button class="share-document__button" @click="sendDocument()" :disabled="invalidDocument">
          <span> {{ $t("myLoads.shareDocument.send") }} </span>
        </button>
      </div>
    </b-overlay>
  </div>
</template>

<script>
/* eslint-disable */
import ValidateEmptyFields from "@/utils/validateEmptyFields";
import Chips from "@/components/Chips.vue";
import ValidateEmail from "@/utils/EmailValidation";

export default {
  name: "ShareDocument",
  components: {
    Chips,
  },
  props: {
    documentType: String,
    documentInformation: Object,
    isCargoCare: Boolean,
    isPaymentsDocument: Boolean,
    insuranceId: String,
    selectedPaymentDocument: Object
  },
  data() {
    return {
      body: {
        emails: [],
        documentName: "",
        emailMessage: "",
      },
      emptyFields: {
        emails: false,
        documentName: false,
        emailMessage: false,
      },
      loadingShare: false,
      invalidDocument: false,
      validEmails: false,
    };
  },
  created() {
    if (this.isCargoCare) {
      this.body.documentName = `${this.documentInformation.name}`;
      return;
    }
    let typeOfDoc = "";
    if (this.documentType === "invoice") {
      typeOfDoc = "invoice";
    } else if (this.documentInformation.url.includes("generated-docs")) {
      const parts = this.documentInformation.url.split("/");
      typeOfDoc = parts[parts.indexOf("generated-docs") + 1];
    } else {
      typeOfDoc = "my-docs";
    }
    if (typeOfDoc === "invoice") {
      this.body.documentName = `${typeOfDoc}/${this.documentInformation.name}`;
    } else {
      const urlParts = this.documentInformation.url.split("/");
      const fileName = urlParts[urlParts.length - 1].split("?")[0];
      this.body.documentName = `generated-docs/${typeOfDoc}/${fileName}`;
    }
  },
  methods: {
    async sendDocument() {
      const validateForm = this.validateFormFields();
      if (validateForm) {
        this.deleteInvalidEmails();
        this.loadingShare = true;
        if (this.documentType === "invoice") {
          this.body.documentName = `invoice/${this.$route.params.loadId}`;
        }
        let response;
        if (this.isCargoCare) {
          const params = {
            id: this.insuranceId,
            body: {
              email: this.body.emails,
              Query: [this.body.documentName],
              mail_content: this.body.emailMessage,
            },
          };
          response = await this.$store.dispatch("insurance/shareInsuranceFiles", params);
          this.$emit('handleCloseModal');
        } else if(this.isPaymentsDocument) {
          const body = {
            email: this.body.emails,
            mail_content: this.body.emailMessage,
            path: this.selectedPaymentDocument.documentPath
          }
          response = await this.$store.dispatch("load/sendBilling", body);
        } else {
          const params = {
            id: this.$route.params.loadId,
            body: {
              email: this.body.emails,
              Query: [this.body.documentName],
              mail_content: this.body.emailMessage,
            },
          };
          response = await this.$store.dispatch("share/postShareFile", params);
        }
        if (response) {
          this.swal(
            this.$t("bookALoad.goodJob"),
            this.$t("myLoads.shareDocument.emailSent"),
            "success"
          );
          this.$emit("closeShareDocumentModal");
        }
        this.loadingShare = false;
      }
    },
    validateFormFields() {
      const validateForm = ValidateEmptyFields(this.body);
      this.resetEmptyFieldsValues();
      const validateEmail = this.body.emails.filter((email) => ValidateEmail(email));
      validateForm.emptyFields.forEach((field) => {
        this.emptyFields[field] = true;
      });
      if (validateEmail.length && validateForm.validation) {
        return true;
      }
      return false;
    },
    resetEmptyFieldsValues() {
      const keys = Object.keys(this.emptyFields);
      keys.forEach((key) => {
        this.emptyFields[key] = false;
      });
    },
    deleteInvalidEmails() {
      this.body.emails = this.body.emails.filter((email) => ValidateEmail(email));
    },
    analyzeValidEmails() {
      const emails = this.body.emails.filter((email) => ValidateEmail(email));
      if (emails.length > 0) {
        this.validEmails = false;
      } else {
        this.validEmails = true;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.share-document {
  font-family: $font-family-portal;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  &__input-border {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border: 1px solid $color-border-container;
    background-color: white;
    height: 35px;
    width: 100%;
    max-width: 350px;
    box-shadow: none;
    border-radius: 10px;
  }
  &__label {
    color: $color-primary-company;
    font-weight: 700;
  }
  &__input {
    @include input-information;
    border: none;
    height: 33px;
    width: 100%;
    max-width: 300px;
    margin-left: 7px;
    box-shadow: none;
    border-radius: 10px;
    &:hover {
      box-shadow: none;
    }
    &:focus-within {
      box-shadow: none;
    }
  }
  &__emails {
    @include input-information;
    margin-top: 0;
    border: 1px solid $color-border-container;
    min-height: 40px;
    height: auto;
    width: 100%;
    padding: 0px 0px 0px 5px;
    box-shadow: none;
    border-radius: 10px;
    &:hover {
      box-shadow: none;
    }
    &:focus-within {
      box-shadow: none;
    }
  }
  &__tooltip-icon {
    @include icon-input;
    padding: 0;
    margin: 0 4px;
  }
  &__message {
    border: 1px solid $color-border-container;
    background-color: white;
    border-radius: 10px;
    width: 100%;
    box-shadow: none;
    color: black;
    font-weight: normal;
    font-size: 14px;
    &:hover {
      box-shadow: none;
    }
    &:focus-within {
      box-shadow: none;
    }
    &::placeholder {
      color: #b9b9b9;
    }
  }
  &__document {
    display: flex;
    align-items: center;
    border: 1px solid $color-border-container;
    border-radius: 10px;
    background-color: white;
    height: 45px;
    width: 100%;
    padding: 7px;
    box-shadow: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    span {
      font-size: 14px;
    }
  }
  &__document-icon {
    color: red;
    font-size: 18px;
    margin-right: 5px;
  }
  &__button-container {
    display: flex;
  }
  &__button {
    @include secondary-button;
    @include font-button-text;
    padding: 7px 5px;
    width: 50%;
    font-weight: 400;
    margin: 15px auto;
    &:disabled {
      background-color: grey;
    }
  }
  &__missing-text {
    font-size: 12px;
    color: red;
  }
  &__missing-email {
    font-size: 12px;
    color: red;
    position: absolute;
    bottom: -17px;
    right: 0px;
  }
}
hr {
  width: 100%;
  margin-top: 0;
}
.input-information-email {
  width: 100%;
  border: none;
  display: flex;
  align-items: center;
}
.input-container {
  position: relative;
}
.chips-container {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.missing-field {
  background-color: $missing-fields;
}
.close-button {
  @include modals-close-button;
}
.missing-field ::v-deep .chips-container__input {
  background-color: $missing-fields;
}
::v-deep .spinner-border {
  color: $color-primary-company;
}
</style>
